// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-anger-management-js": () => import("./../src/pages/anger-management.js" /* webpackChunkName: "component---src-pages-anger-management-js" */),
  "component---src-pages-contact-me-js": () => import("./../src/pages/contact-me.js" /* webpackChunkName: "component---src-pages-contact-me-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-services-offered-js": () => import("./../src/pages/services-offered.js" /* webpackChunkName: "component---src-pages-services-offered-js" */)
}

